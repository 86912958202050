<template>
	<div>
		<v-stepper
			class="elevation-0"
			v-model="e6"
			vertical
			>
			<v-stepper-step
				:complete="e6 > 1"
				step="1"
				@click="e6 = 1"
				:rules="step1"
				>
				<span class="stepper-step-proceed">Account Information</span>
			</v-stepper-step>

			<v-stepper-content step="1">
				<v-card
					:elevation="0"
					class="mb-6"
					>
						<v-form ref="step1" v-model="step1form">
							<account-information />
						</v-form>
				</v-card>
				<v-divider></v-divider>
				<v-row class="mt-2">
					<v-col>
						<v-btn
							color="primary"
							@click="$router.push({path: '/sales-prospecting'})"
							outlined
							>
							<b>Back</b>
						</v-btn>
						<v-btn
							@click="e6 = 2"
							color="info"
							style="float:right;"
							>
							<b>Continue</b>
						</v-btn>
						<v-btn
							@click="save(true)"
							class="mr-4"
							outlined
							color="primary"
							style="float:right;"
							>
							<b>Save As Draft</b>
						</v-btn>
					</v-col>
				</v-row>
			</v-stepper-content>

			<v-stepper-step
				:complete="e6 > 2"
				step="2"
				@click="e6 = 2"
				:rules="step2"
				>
				<span class="stepper-step-proceed">Sales Presentation Information</span>
			</v-stepper-step>

			<v-stepper-content step="2">
				<v-card
					:elevation="0"
					class="mb-6"
					>
					<v-form ref="step2" v-model="step2form">
						<sales-presentation-information />
					</v-form>
				</v-card>
				<v-divider></v-divider>
				<v-row class="mt-2">
					<v-col>
						<v-btn
							color="primary"
							@click="e6 -= 1"
							outlined
							>
							<b>Back</b>
						</v-btn>
						<v-btn
							@click="e6 = 3"
							color="info"
							style="float:right;"
							v-if="salesProspectingForm.accept_or_negotiate === 'Accepts Partnership'"
							>
							<b>Continue</b>
						</v-btn>
						<v-btn
							class="mr-4"
							@click="save(true)"
							color="info"
							style="float:right;"
							v-if="salesProspectingForm.accept_or_negotiate === 'Does Not Accept Partnership'"
							>
							<b>Submit</b>
						</v-btn>
						<v-btn
							v-else
							class="mr-4"
							@click="save(true)"
							outlined
							color="primary"
							style="float:right;"
							>
							<b>Save as Draft</b>
						</v-btn>
					</v-col>
				</v-row>
			</v-stepper-content>

			<v-stepper-step
				:complete="e6 > 3"
				step="3"
				@click="salesProspectingForm.accept_or_negotiate === 'Accepts Partnership' ? e6 = 3 : ''"
				:rules="step3"
				>
				<span :class="salesProspectingForm.accept_or_negotiate === 'Accepts Partnership' ? 'stepper-step-proceed' : 'stepper-step-forbidden'">Score Card</span>
			</v-stepper-step>

			<v-stepper-content step="3">
				<v-card
					:elevation="0"
					class="mb-6"
					>
					<v-form ref="step3" v-model="step3form">
						<score-card />
					</v-form>
				</v-card>
				<v-divider></v-divider>
				<v-row class="mt-2">
					<v-col>
						<v-btn
							color="primary"
							@click="e6 -= 1"
							outlined
							>
							<b>Back</b>
						</v-btn>
						<v-btn
							@click="e6 = 4"
							color="info"
							style="float:right;"
							>
							<b>Continue</b>
						</v-btn>
						<v-btn
							class="mr-4"
							@click="save(true)"
							outlined
							color="primary"
							style="float:right;"
							>
							<b>Save As Draft</b>
						</v-btn>
					</v-col>
				</v-row>
			</v-stepper-content>

			<v-stepper-step
				:complete="e6 > 4"
				step="4"
				@click="salesProspectingForm.accept_or_negotiate === 'Accepts Partnership' ? e6 = 4 : ''"
				:rules="step4"
				>
				<span :class="salesProspectingForm.accept_or_negotiate === 'Accepts Partnership' ? 'stepper-step-proceed' : 'stepper-step-forbidden'">Supporting Documents</span>
			</v-stepper-step>

			<v-stepper-content step="4">
				<v-card
					class="mb-1"
					:elevation="0"
					>
					<supporting-documents />
				</v-card>
				<v-divider class="my-5"></v-divider>
				<v-row>
					<v-col>
						<v-btn
							color="primary"
							@click="e6 -= 1"
							outlined
							>
							<b>Back</b>
						</v-btn>
						<v-btn
							@click="save(false)"
							color="info"
							style="float:right;"
							>
							<b>Submit For Approval</b>
						</v-btn>

						<v-btn
							v-if="initialUploaded.profile_picture ||initialUploaded.upload_loi || initialUploaded.upload_picture || initialUploaded.upload_supp_docs"
							class="mr-4"
							outlined
							color="primary"
							style="float:right;"
							@click="upload"
							>
							<b>Upload Documents</b>
						</v-btn>
						
						<v-btn
							v-else
							class="mr-4"
							outlined
							color="primary"
							style="float:right;"
							disabled
							>
							<b>Upload Documents</b>
						</v-btn>

					</v-col>
				</v-row>
			</v-stepper-content>
		</v-stepper>	
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import jsonToFormData from 'json-form-data';
import ScoreCard from '../components/modules/scorecard/ScoreCard.vue';
import AccountInformation from '../components/modules/salesProspecting/AccountInformation.vue';
import SalesPresentationInformation from '../components/modules/salesProspecting/SalesPresentationInformation.vue'
import SupportingDocuments from '../components/modules/salesProspecting/SupportingDocuments.vue'

export default {
	components: {
		ScoreCard: ScoreCard,
		AccountInformation: AccountInformation,
		SalesPresentationInformation: SalesPresentationInformation,
		SupportingDocuments: SupportingDocuments
	},
	mounted () {
		console.log('SP NEW')
		console.log(this.spNumber)
		if (this.spNumber === '') { 
            const payload = {
				is_existing: false
			}
			this.$store.dispatch('salesProspecting/doCreateSPNumber', payload)
		} else {
			const payload = {
				is_existing: false
			}
			this.$store.dispatch('salesProspecting/doCreateSPNumber', payload)
		}
		// if (this.spNumber === '') { 
		// 	this.$store.dispatch('salesProspecting/doCreateSPNumber', {is_existing: JSON.parse(this.$route.params.existing)})
		// 	console.log(this.spNumber)
		// }
	},
	data: () => ({ 
		e6: 1,
		step1: [],
		step2: [],
		step3: [],
		step4: [],
		step1form: true,
		step2form: true,
		step3form: true,
		steps: ['step1', 'step2', 'step3', 'step4'],
		partialSteps: ['step1', 'step2']
	}),
	computed: {
		...mapGetters({
			salesProspectingForm: 'salesProspecting/salesProspectingForm',
			spNumber: 'salesProspecting/spNumber',
			initialUploaded: 'salesProspecting/initialUploaded',
			existingInitialUploaded: 'salesProspecting/existingInitialUploaded'
		}),
	},
	methods: {
		saveNonAcceptance () {
			const self = this
			self.$store.commit('salesProspecting/SET_RULE_GENERIC')
			self.$store.commit('salesProspecting/SET_RULE_CHECKBOX')
			setTimeout(() => {
				self.partialSteps.forEach(e => {
					if (!self.$refs[e].validate()) {
						self[e] = [() => false]
						self.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'There are fields that have not been filled out!','messagetype':'error'},{ root: true });
					} else {
						self[e] = []
					}
				})
				if (self.$refs.step1.validate() && self.$refs.step2.validate()) {
					self.steps.forEach(e => {
						self[e] = []
					})
					// this.callSave(payload)
				}
			})
		},
		save (isDraft) {
			var options = {
				initialFormData: new FormData(),
				howLeafArrayIndexes: true,
				includeNullValues: false,
				mapping: function(value) {
					if (typeof value === 'boolean') {
						return +value ? '1': '0';
					}
					return value;
				}
			};
			let finalPayload =  _.cloneDeep(this.salesProspectingForm);
			finalPayload.scorecard_result = this.computeFinalRating()
			finalPayload.is_draft = isDraft
			var convertedFormData = jsonToFormData(finalPayload, options)
			const payload = {id: this.spNumber.id, payload: convertedFormData}			
			if (isDraft) {
				this.callSave(payload)
			} else {
				const self = this
				self.$store.commit('salesProspecting/SET_RULE_GENERIC')
				self.$store.commit('salesProspecting/SET_RULE_CHECKBOX')
				self.$store.commit('salesProspecting/SET_RULE_SCORECARD')
				setTimeout(() => {
					var step4Validated = false
					self.steps.forEach(e => {
						if (e === 'step4') {
							if (self.existingInitialUploaded.upload_picture && self.existingInitialUploaded.upload_loi && self.existingInitialUploaded.upload_supp_docs) {
								step4Validated = true
							} else {
								console.log('Here')
								self.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'File Uploads are not complete!','messagetype':'error'},{ root: true });
								self[e] = [() => false]
							}
						} else {
							if (!self.$refs[e].validate()) {
								self[e] = [() => false]
								self.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'There are fields that have not been filled out!','messagetype':'error'},{ root: true });
							} else {
								self[e] = []
							}
						}
					})
					if (self.$refs.step1.validate() && self.$refs.step2.validate() && self.$refs.step3.validate() && step4Validated) {
						self.steps.forEach(e => {
							self[e] = []
						})
						this.callSave(payload)
					}
				})
			}
		},
		async callSave (payload) {
			if (this.spNumber.is_existing == false){
				var link = '/sales-prospecting'
			}
			const res = await this.$store.dispatch('salesProspecting/doSaveSalesProspecting', {payload})
			if(res.status === 200) {
				this.$store.commit('salesProspecting/SET_RULE_GENERIC')
				this.$store.commit('salesProspecting/SET_RULE_CHECKBOX')
				this.$store.commit('salesProspecting/SET_RULE_SCORECARD')
				this.$router.push(link)
			} else if (res.code == 422 || res.code == 400){
                this.$store.dispatch('app/setSnackBar',{'status': true,'message': res.error ,'messagetype':'error'});
            }
		},
		computeFinalRating () {
			if(typeof this.salesProspectingForm.scorecard !== 'string'){
				var total = 0
				this.salesProspectingForm.scorecard.forEach(function(e){
					e.criteria.forEach(function(f){
						total += (parseInt(f.score) * (f.weight / 100))
					})
				})
				if(isNaN(total)){
					return ''
				}
				return ((total / 4) * 100).toFixed(2)
			}
		},
		async upload () {
			console.log('here')
			console.log(this.initialUploaded)
			console.log(typeof this.initialUploaded)
			var options = {
				initialFormData: new FormData(),
				howLeafArrayIndexes: true,
				includeNullValues: false,
				mapping: function(value) {
					if (typeof value === 'boolean') {
						return +value ? '1': '0';
					}
					return value;
				}
			};
			let finalPayload =  _.cloneDeep(this.initialUploaded);
			var convertedFormData = jsonToFormData(finalPayload, options)
			const payload = {id: this.spNumber.id, payload: convertedFormData}
			const res = await this.$store.dispatch('salesProspecting/doInitialUpload', {payload})
			console.log(res)
			
			this.reloadUploads()
		},
		async reloadUploads () {
			const res = await this.$store.dispatch('salesProspecting/doGetSalesProspecting', this.spNumber.id)
			this.decodeUploads(res.data.result[0].sp_primary_details)
			this.$store.commit('salesProspecting/SET_DEFAULT_INITIAL_UPLOADED_FILES')
		},
		decodeUploads (resUpload) {
			if (resUpload.upload_loi !== null) {
				resUpload.upload_loi = JSON.parse(resUpload.upload_loi)
			}
			if (resUpload.upload_picture !== null) {
				resUpload.upload_picture = JSON.parse(resUpload.upload_picture)
			}
			if (resUpload.upload_supp_docs !== null) {
				resUpload.upload_supp_docs = JSON.parse(resUpload.upload_supp_docs)
			}
			this.$store.commit('salesProspecting/SET_EXISTING_INITIAL_UPLOADED_FILES', resUpload)
		}
	}
};
</script>
<style scoped>
	.stepper-step-proceed {
		cursor: pointer;
	}
	.stepper-step-forbidden {
		cursor: no-drop;
	}
</style>